.BA-container {
  margin-top: 10rem;
}
.tabs .tab {
  cursor: pointer;
}
.tabs .tab.active-tab .border-bottom {
  background: black;
}
.list p {
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 10px;
  padding-top: 70px;
  font-size: 16px;
}
.content {
  display: none;
}
.active-tabb::after {
  content: "";
  display: block;
  width: 300px;
  margin-left: 20px;
  border-bottom: 1px solid black;
}
.border-bottom {
  background: #b5b5b3;
  height: 1px;
  width: 100%;
}
.show-content {
  display: block;
}
.designer-heading {
  color: #0d0b07;
  text-align: center;
  font-family: Isidora Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 265;
  line-height: 48px;
  text-transform: capitalize;
}
.designer-price {
  color: #0d0b07;
  text-align: center;
  font-family: Isidora Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  text-transform: capitalize;
}
.BA-big-heading2 {
  padding-bottom: 10px;
}
.BA-text {
  padding-top: 20px;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  line-height: 28px;
  letter-spacing: 0.4px;
  color: #110f09;
}
.BA-text1 {
  text-align: justify;
  font-size: 20px;
  font-style: normal;
  line-height: 28px; /* 120% */
  letter-spacing: 0.4px;
  color: #110f09;
  /* padding-bottom: 10px; */
}
.designer-text {
  color: #060503;
  text-align: justify;
  font-family: Isidora Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.last-contact-button1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 120px;
  background: #000;
  padding: 24px 32px;
  gap: 8px;
  color: #e7e7e6;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 12rem;
  margin-top: 30px;
}
a {
  text-decoration: none;
}
@media (max-width: 760px) {
  .BA-container {
    margin-top: 8.7rem;
  }
  .BA-heading {
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 4.8px;
    text-transform: uppercase;
  }
  .BA-big-heading {
    font-size: 27px;
    line-height: 32px;
  }
  .BA-text,
  .BA-text1,
  .BA-text li {
    font-size: 16px;
    line-height: 24px;
  }
  .BA-big-heading2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 200;
    line-height: 32px;
    text-transform: capitalize;
  }
  .list p {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-top: 30px;
  }

  .active-tabb::after {
    content: "";
    display: block;
    width: 150px;
    margin-left: 5px;
    border-bottom: 1px solid black;
  }
  .border-bottom {
    background: #b5b5b3;
    height: 0.5px;
  }
  .designer-heading {
    font-family: Isidora Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 265;
    line-height: 24px;
    text-transform: capitalize;
  }
  .designer-price {
    line-height: 32px;
    text-transform: capitalize;
  }
  .last-contact-button1 {
    margin-top: 2rem;
    padding: 16px;
    font-size: 14px;
    margin-bottom: 6rem;
  }
  .designer-price {
    font-size: 25px;
  }
}
