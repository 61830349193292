.contact-start {
  margin-top: 7rem;
  margin-bottom: 5rem;
}
.contact-heading {
  font-size: 16px;
  font-weight: 300;
  color: #59544b;
}
.contact-text {
  font-size: 64px;
  font-weight: 300;
  line-height: 96px;
  letter-spacing: -1.28px;
  text-transform: capitalize;
  color: #101828;
}
.contact-tiny-text {
  margin-top: -10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.4px;
  color: #667085;
}
.contactImage {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.containerr .contact {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 5rem;
  margin-top: 7rem;
}
.containerr button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 120px;
  border: 1px solid #060503;
  background: transparent;
  padding: 30px 36px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.4px;
  cursor: pointer;
}
.name-email {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.name,.email{
  width: 50%;
}
label {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.service-option-container{
  display: flex;
  justify-content: space-between;
  width: 70%;
}
input,
.textt {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  width: 100%;
  color: #000;
  outline: #b5b5b3;
  border-radius: 12px;
  border: 1px solid #b5b5b3;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.react-tel-input .form-control {
color: #b5b5b3;
  padding: 24px 32px;
  width: 100% !important;
  border-radius: 12px !important;
  border: 1px solid #b5b5b3 !important;
  background: #fff !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
.divider .left,
.divider .right {
  width: 630px;
  height: 0.5px;
  background: #000;
}
.form {
  margin-top: 3rem;
  margin-bottom: 10rem;
}
.number {
  width: 100%;
}
.last-contact-button {
  margin-top: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 120px;
  background: #000;
  padding: 24px 32px !important;
  gap: 8px;
  color: #e7e7e6;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px; 
  text-transform: uppercase;
  margin-bottom: 10rem;
  cursor: pointer;
}
@media (max-width: 815px) {
  .contact-start {
  margin-top: 5.5rem;
    margin-bottom: 1rem ;
  }
  .contact-heading{
    font-size: 10px;
  }
  .contact-text {
    font-size: 24px;
    font-style: normal;
    font-weight: 265;
    line-height: 32px;
    text-transform: capitalize;
  }
  .contact-tiny-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .containerr .contact {
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .divider .left,
  .divider .right {
    width: 170px;
    height: 0.5px;
    background: #000;
  }
  .form {
    margin-top: 1rem;
    margin-bottom: 9rem;
  }
  .containerr button {
    padding: 12px 20px;
    font-size: 14px;
    margin-bottom: 1rem;
    line-height: 24px;
  }
  .name-email {
   flex-direction: column;
    gap: 0px;
  }
  .name,.email{
    width: 100%;
  }
  label,
  .service-option {
    font-size: 16px;
    line-height: 10px;
  }
  .service-option-container{
    display: flex;
    /* justify-content: center; */
    width: 100%;
  }
  input,
  .textt {
    display: flex;
    padding: 10px 12px;
    align-items: center;
    width: 100%;
  }
  .react-tel-input .form-control {
    color: #b5b5b3;
      padding: 24px 32px !important;
    }
    .last-contact-button {
      margin-top: 3rem;
      padding: 10px 10px !important;
      font-size: 14px;
      margin-bottom: -5rem;
    }

}
@media (min-width:816px) and (max-width:1199px){
  .containerr button {
    padding: 20px 26px !important;
    font-size: 20px;
  }
}
