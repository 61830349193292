.couture-start{
  margin-top: 7rem;
}
.couture-heading {
  text-align: center;
  font-family: Isidora Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 7.68px;
  text-transform: uppercase;
  color: #524a44;
}
.couture-text {
  font-family: Isidora Sans;
  font-size: 68px;
  font-style: normal;
  font-weight: 265;
  line-height: 72px;
  letter-spacing: -1.28px;
  text-transform: capitalize;
  color: #060503;
}
.couture-tiny-text {
  font-family: Isidora Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.couture-process-container {
  display: flex;
  padding: 120px 48px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  align-self: stretch;
  background: #fdf0d5;
  backdrop-filter: blur(20px);
}
.couture-process-container2 {
  display: flex;
  padding: 120px 48px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  align-self: stretch;
}
.couture-process-container img, .couture-process-container2 img{
  border-radius: 40px;
  width: 450px;
  height: 400px;
  object-fit: cover;
}
.numbering {
  font-family: Isidora Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
  letter-spacing: 7.68px;
  text-transform: uppercase;
  color: #252525;
  margin-bottom: 10px;
}
.blur-box {
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
}
.couture-process {
  font-family: Isidora Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
  text-transform: capitalize;
}
.blur-box .contentt {
  color: #000;
  font-family: Isidora Sans;
  font-size: 1.4rem;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0.6px;
  text-align: justify;
  margin-top: 10px;
}
.couture-footer{
  margin-top: 70px;
  margin-bottom: 90px;
}
.couture-footer .tiny-text {
  color: #524a44;
  text-align: center;
  font-family: Isidora Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
  letter-spacing: 7.68px;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.couture-footer .big-text {
  color: #060503;
  text-align: center;
  font-family: Isidora Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 265;
  line-height: 48px; /* 120% */
  text-transform: capitalize;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
  align-self: stretch;
  margin-top: 40px;
}
.button {
  border-radius: 120px;
  padding: 24px 32px;
  border: none;
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(16, 15, 20, 0.05);
  color: #fffefb;
  background-color: #070603;
}
.button2 {
  border-radius: 120px;
  padding: 22px 30px;
  border: 1px solid #070603;
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(16, 15, 20, 0.05);
  background-color: #fffefb;
}
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 30px;
  /* margin-bottom: 30px; */
}
.button{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 36px;
  background: #000;
  color: #fff;
  border-radius: 120px;
  font-family: Isidora Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  border: none;
  outline: none;
  text-transform: uppercase;
}
.button2{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 34px;
  background: #fff;
  color: #000;
  border-radius: 120px;
  font-family: Isidora Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  border: 1px solid #000;
  outline: none;
  text-transform: uppercase;
}


@media (max-width: 480px) {
  .couture-start{
    margin-top: 5.5rem;
  }
  .couture-heading {
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 4.8px;
  }
  .couture-text {
    font-size: 24px;
    font-style: normal;
    font-weight: 265;
    line-height: 32px;
  }
  .couture-tiny-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .couture-footer .tiny-text {
    padding-top: 3px;
    padding-bottom: 1px;
  }
  .couture-process-container,
  .couture-process-container2 {
    padding: 50px 8px;
    gap: 20px;
  }
  .couture-process-container {
    flex-direction: column;
  }
  .couture-process-container2 {
    flex-direction: column-reverse;
  }
  .couture-process-container img, .couture-process-container2 img{
    border-radius: 40px;
  }
  .couture-img img {
    width: 350px ;
    height: 350px ;
  }
  .couture-process {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }
  .numbering{
    margin-bottom: 5px;
    margin-top: 15px;
  }
  .blur-box .contentt {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px !important;
    margin-top: 5px;
  }
  .couture-footer .tiny-text {
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 4.8px;
  }
  .couture-footer .big-text {
    font-size: 22px !important;
    line-height: 32px !important;
  }
  .buttons {
    gap: 8px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .button {
    padding: 12px 20px;
    font-size: 13.8px;
  }
  .button2 {
    padding: 10px 18px;
    font-size: 13.8px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .couture-text {
    font-size: 58px;
    line-height: 62px;
  }
  .couture-process-container {
    flex-direction: column;
    gap: 30px;
  }
  .couture-process-container2 {
    flex-direction: column-reverse;
    gap: 30px;
  }
  .blur-box {
    background: none;
    padding: 10px 20px;
  }
  .blur-box .contentt {
    font-size: 1rem;
    line-height: 30px;
  }
  .couture-footer .tiny-text {
    font-size: 14px;
  }
  .couture-footer .big-text {
    font-size: 34px;
  }
  .button,
  .button2 {
    padding: 20px 28px;
  }
  .button p,
  .button2 p {
    font-size: 17px;
  }
}
@media (min-width: 766px) and (max-width: 820px) {
  .couture-process-container {
    flex-direction: column;
    gap: 30px;
  }
  .couture-process-container2 {
    flex-direction: column-reverse;
    gap: 30px;
  }

  .blur-box .contentt {
    font-size: 1.1rem;
    line-height: 30px;
  }
  .couture-img img {
    width: 600px ;
    height: 600px ;
  }
  .couture-footer .tiny-text {
    font-size: 14px;
  }
  .couture-footer .big-text {
    font-size: 34px;
  }
  .button,
  .button2 {
    padding: 20px 28px;
  }
  .button2 {
    padding: 19px 27px;
  }
  .button p,
  .button2 p {
    font-size: 17px;
  }
}
@media (min-width: 820px) and (max-width: 1200px) {
  .couture-heading {
    font-size: 14px;
    line-height: 24px;
  }
  .couture-text {
    font-size: 64px;
  }
  .couture-tiny-text {
    font-size: 17px;
  }
  .couture-process-container,
  .couture-process-container2 {
    padding: 80px 38px !important;
    gap: 20px !important;
  }
  .couture-img img {
    width: 400px ;
    height: 400px ;
  }
  .couture-process {
    font-size: 35px !important;
  }
  .blur-box .contentt {
    font-size: 1.1rem;
    line-height: 30px;
  }
  .couture-footer .tiny-text {
    font-size: 14px;
  }
  .couture-footer .big-text {
    font-size: 34px;
  }
  .button,
  .button2 {
    padding: 20px 28px;
  }
  .button2 {
    padding: 19px 27px;
  }
  .button p,
  .button2 p {
    font-size: 17px;
  }
  
}

