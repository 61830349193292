@font-face {
  font-family: "Isidora Sans";
  src: local("Isidora Sans"),
    url("../src/Fonts//Isidora/IsidoraSans-Regular.ttf") format("truetype");
}
* {
  font-family: "Isidora Sans";
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

