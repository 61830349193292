* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.subNav {
  width: 100%;
  background: #fff;
  margin-top: 2rem;
}
.border-bottom{
  background: #b5b5b3;
  height: 1px;
  width: 100%;
  margin: auto;
}
.active-tab {
    border-bottom: 1px solid black;
}
.storeNav {
  width: 100%;
}
.storeNav .list  p{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #060503;
  text-decoration: none;
  cursor: pointer;
}
.store .tiny-heading {
  color: #524a44;
  text-align: center;
  font-family: Isidora Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 7.68px;
  text-transform: uppercase;
}
.store .heading {
  color: #060503;
  text-align: center;
  font-family: Isidora Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 265;
  line-height: 48px;
  text-transform: capitalize;
}
.store .text {
  color: #0d0b07;
  text-align: center;
  font-family: Isidora Sans;
  font-size: 20px;
  font-style: normal;
  width: 90%;
  margin: auto;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.4px;
}
.gallery {
  width: 100%;
}
.gallery2 {
  width: 100%;
}
.gallery2 .roww {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 3px; 
  justify-content: center;
}
.gallery2 .roww img {
  width: 100%;
  height: 600px;
  object-fit: cover;
}
.gallery-accessory {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  justify-content: center;
}
.gallery-accessory img {
  width: 100%;
  object-fit: contain;
}

@media (max-width: 815px) {
  .storeNav {
    width: 100%;
    align-items: center !important;
  }
  .active-tab { 
      /* display: block; */
      /* width: 60px;  */
      border-bottom: 1px solid black;
  }
  .subNav {
    width: 100%;
    background: #fff;
    margin-top: 4rem;
  }
  .storeNav .list  p{
    font-size: 13px;
    font-weight: 400;
    padding-left: 0px;
    padding-right: 0px;
  }
  .store .tiny-heading {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 4.8px;
  }
  .store .heading {
    font-size: 24px;
    line-height: 32px;
  }
  .store .text {
    font-size: 16px;
    line-height: 24px;
  }
  .gallery2 .roww {
    grid-template-columns: repeat(2, 1fr); 
    gap: 1px; 
  }
  .gallery2 .roww img {
    height: 300px;
  }
  .gallery-accessory {
    grid-template-columns: repeat(2, 1fr); 
  }
  .gallery-accessory img {
    width: 100%;
    object-fit: contain;
  }
}
