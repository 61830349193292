.footer {
  background-color: #fdf0d5;
  margin-top: -25px;
  display: flex;
  flex-direction: column;
  padding: 96px 0px 80px 0px;
}
.foot {
 width: 90%;
 margin: auto;
 text-align: center;
}
.logo img{
    width: 100px;
    height: 50px;
    object-fit: contain;
}

.logo {
  display: flex;
  justify-content: center;
}
.list .listItem {
  color: #fffefb !important;
}
.copyright-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}
.borderBottom {
  background: #252525;
  height: 0.5px;
  width: 90%;
  margin: auto;
}
.copyright p {
  color: #252525;
}

.footer-tiny-text {
  font-family: Isidora Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
  letter-spacing: 7.68px;
  text-transform: uppercase;
  color: #252525;
}
.footer-normal-text {
  color: #252525;
  font-family: Isidora Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  letter-spacing: 0.4px;
}
.desktop-view .footer-tiny-text {
  font-family: Isidora Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 7.68px;
  text-transform: uppercase;
}
.desktop-view .footer-normal-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1.5px;
}
@media (max-width: 480px){
    .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
     .foot{
        text-align: left;
     }
      .copyright-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .icons {
        margin-top: 10px;
      }
      .logo {
        justify-content: left;
      }
      .desktop-view .footer-tiny-text{
        font-size: 10px;
      }
      .desktop-view .footer-normal-text{
        font-size: 16px;
      }
      .copyright p {
       font-size: 16px;
      }
}
@media (min-width: 481px) and (max-width: 767px) {
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
 .foot{
    text-align: left;
 }
  .copyright-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .icons {
    margin-top: 10px;
  }
  .logo {
    justify-content: left;
  }
}
@media (max-width: 768px) {
}
