/* .aboutImg img .aboutImg2 img {
  width: 300px;
  object-fit: cover;
} */
.aboutImg img{
  width: 100%;
height: 100vh;
object-fit: cover;
}
.aboutImg2{
  display: flex;
  justify-content: center;
}
.aboutImg2 img{
  width: 100% ;
object-fit: cover;
border-radius: 50px;
}
.aboutImgRes {
  display: none;
}
.about-heading{
    padding-top: 90px;
    padding-bottom: 10px;
}
.about-text:last-child{
  padding-bottom: 90px;
}
.evi-mob{
  display: none;
}
.about{
  padding-top: 90px;
    padding-bottom: 10px;
}
.about .home-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: justify;
  color: #110f09;
}
.about .text-left {
  color: #110f09;
  text-align: justify;
  font-family: Isidora Sans;
  /* font-size: 20px; */
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
}
.text-left.tiny {
  font-size: 16px;
  margin-top: -28px;
}
.text-left.signature2 {
  color: #110f09;
  font-family: "Style Script", cursive;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  padding-bottom: 120px;
}
.about-heading {
  color: #110f09;
  text-align: center;
  font-family: Isidora Sans;
  font-size: 54px;
  font-style: normal;
  font-weight: 265;
  line-height: 72px; /* 112.5% */
  letter-spacing: -1.28px;
  text-transform: capitalize;
}
.about-text {
  color: #110f09;
  text-align: justify;
  font-family: Isidora Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.designer-tiny-heading {
  color: #0d0b07;
  text-align: center;
  font-family: Isidora Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
  letter-spacing: 7.68px;
  text-transform: uppercase;
}
.designer-big-heading {
  color: #110f09;
  text-align: center;
  font-family: Isidora Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
  text-transform: capitalize;
}
.designer-intro {
  color: #110f09;
  text-align: justify;
  font-family: Isidora Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding-top: 16px;
}
@media (max-width: 815px) {
  .aboutImg {
    display: none;
  }
  .aboutImgRes {
    display: block;
  }
  .aboutImgRes img{
    width: 100%;
    /* height: 500px; */
    object-fit: cover ;
  }
  .evi-mob{
    display: block;
  }
  .evi-desk{
    display: none;
  }
  .about-text:last-child{
    padding-bottom: 30px;
  }
  .about-text {
    font-family: Isidora Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .about-heading {
    font-size: 40px;
    font-style: normal;
    font-weight: 265;
    line-height: 32px;
    padding-top: 50px;
  }
  .about{
    padding-top: 40px;
  }
  .designer-tiny-heading {
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 4.8px;
  }
  .designer-big-heading {
    font-size: 24px;
    font-style: normal;
    font-weight: 200;
    line-height: 20px;
    /* padding-bottom: 15px !important; */
  }
  .designer-intro {
    color:#110f09;
    text-align: justify;
    font-family: Isidora Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin: auto;
    padding-top: 10px;
  }
  .signature-one {
    margin-top: 10px;
  }
}
