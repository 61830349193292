.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.nav-logo-container img{
  width: 100px;
  height: 50px;
  object-fit: contain;
}
#navbar-lists-containerr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lists2 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 150px; */
}
.lists2 .listItem2 {
  padding: 0 30px;
  position: relative;
  color: #000;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}
.transparentHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 99;
  padding: 0px 65px;
}
.activee {
  border-bottom: 0.1px solid #000;
}

.transparentheader-button {
  padding: 12px 48px !important;
  border: none !important;
  outline: none !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 15, 20, 0.05);
  color: #fff;
  text-decoration: none;
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 120px;
  background: #000;
  border: none;;
}
#mobile2 {
  color: #000;
  font-size: 20px;
  display: none;
}
.BKappoint3 {
  display: none;
}

@media screen and (max-width: 820px) {
  .transparentHeader {
    padding: 0px 25px;
  }
  .nav-logo-container {
    max-width: 140px;
  }
  .lists2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
  }
  .lists2 .listItem2:first-child{
    margin-top: 30px;
  }
  .lists2 .listItem2 {
    margin-bottom: 45px;
    padding: 2px 30px;
    /* color: #fff; */
  }

  #navbar-lists-containerr {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    padding: 20px 0 170px 0px;
    position: fixed;
    top: 70px;
    right: -800px;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 40px 0 0 10px rgba(0,0,0,0.5);
    z-index: 999;
    transition: 0.3s ease-in-out;
    border-top: 0.1px solid #000;
  }
  #navbar-lists-containerr.active {
    right: 0px;
  }
  .transparentHeader {
    background-color: #fff;
  }
  #mobile2 {
    display: block;
  }
  #mobile2 i {
    cursor: pointer;
  }
  .transparentheader-button {
    display: flex;
    height: 50px;
    padding: 12px 50px;
    justify-content: center;
    align-items: center;
    gap: 8px; 
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 120px;
    background: #060503;
    color: #fff;
    backdrop-filter: blur(2px);
  }
  .BKappoint1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    display: none;
  }
  .BKappoint3{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 820px) and (max-width: 950px) {
  .lists2 {
    /* margin-right: 20px; */
  }
  .lists2 .listItem2 {
    padding: 0px 10px;
    font-size: 12px;
  }
  .transparentheader-button {
    padding: 12px 18px;
    font-size: 13px;
  }
}
@media (min-width: 950px) and (max-width: 1079px) {
  .lists2 .listItem2 {
    padding: 0px 15px;
    font-size: 14px;
  }
  .transparentheader-button {
    padding: 12px 18px;
    font-size: 12px;
  }
}
@media (min-width: 1080px) and (max-width: 1200px) {
  .transparentHeader {
    padding: 0px 25px;
  }
  .lists2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
  }
}
