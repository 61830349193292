.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
#navbar-lists-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navlist-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lists1 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 150px; */
}
.lists1 .listItem1{
  padding: 0 30px;
  position: relative;
  color: rgba(6, 5, 3,0.9);
  text-decoration: none;
  text-transform: capitalize;
  font-size: 16px;
line-height: 24px; 
cursor: pointer;
}
header {
  width: 100%;
}
.whiteHeader {
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  padding: 0px 65px;
}

.header-button {
  padding: 12px 48px !important;
  border: none !important;
  outline: none !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 15, 20, 0.05);
  color: #fff;
  text-decoration: none;
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 120px;
  background: #000;
  border: none;
}
.activee {
  border-bottom: 0.1px solid #000;
}
#mobile2 {
  color: #000;
  font-size: 20px;
  display: none;
}
.BKappoint2 {
  display: none;
}
@media screen and (max-width: 820px) {
  .whiteHeader {
    padding: 0px 25px;
  }
  .nav-logo-container {
    max-width: 140px;
  }
  .lists1{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 0px;    
  }
  .lists1 .listItem1:first-child{
    margin-top: 30px;
  }
  .lists1 .listItem1{
    margin-bottom: 45px;
    padding: 2px 30px;
    color: #000 !important;
  }
  #navbar-lists-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 70px;
    right: -800px;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 40px 0 0 10px rgba(0,0,0,0.5);
    padding: 20px 0 170px 0px;
    transition: 0.3s ease-in-out;
    border-top: 0.1px solid #000;
  }
  #navbar-lists-container.active{
    right: 0px;
  }
  #mobile2 {
    display: block;
  }
  #mobile2 i{
    cursor: pointer;
    transition: 2s ease-in-out;
  }
  .header-button{
    display: flex;
    height: 50px;
padding: 12px 50px;
justify-content: center;
align-items: center;
gap: 8px;
/* border-radius: 8px; */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
border-radius: 120px;
background: #000;
color: #fff;
backdrop-filter: blur(2px);
  }
  .BKappoint{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    display: none;
  }
  .BKappoint2{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    /* margin-bottom: 30px; */
  }
  }
 
  @media (min-width: 820px) and (max-width: 950px) {
    
    .lists{
      margin-right: 20px;
    }
    .lists1 .listItem1{
      padding: 0px 10px;
      font-size: 13px;
    }
    .header-button{
  padding: 12px 18px;
  font-size: 12px;
    }
  }
  @media (min-width: 950px) and (max-width: 1079px) {
    .lists1 .listItem1{
      padding: 0px 15px;
      font-size: 14px;
    }
    .header-button{
  padding: 12px 18px;
  font-size: 12px;
    }
  }

  @media (min-width: 1080px) and (max-width: 1200px) {
    .whiteHeader {
      padding: 0px 25px;
    }
    .lists1{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 50px;
    }
  }
