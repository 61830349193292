video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.vid-text {
  padding-top: 140px;
  padding-bottom: 64px;
}
.vid-text h3 {
  color: #524a44;
  text-align: center;
  font-family: Isidora Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 7.68px;
  text-transform: uppercase;
}
.vid-text p {
  color: #110f09;
  text-align: center;
  font-family: Isidora Sans;
  font-size: 45px;
  font-style: normal;
  font-weight: 265;
  line-height: 48px; /* 120% */
  text-transform: capitalize;
}
.disappear {
  display: none;
}
.disappearing-subcontainer {
  position: relative;
}
.disappear-container {
  display: flex;
}
.disappear-container .column2 {
  width: 50%;
}
.disappear-container .column2 img {
  width: 100%;
  height: 840px;
  object-fit: cover;
}

.disappearing-text {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.disappearing-text .text {
  font-family: Isidora Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  text-transform: capitalize;
}
.discover-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 31rem;
}
.discover-btn .btn2 {
  border-radius: 25px;
  padding: 10px 18px;
  border: 0.2px solid black;
  outline: none;
  background: rgba(255, 255, 255, 0.24);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #060503;
  margin-top: 10px;
  font-weight: bolder;
  letter-spacing: 7.68px;
  text-transform: uppercase;
}
.discover-btn .btn2:hover {
  background: rgb(255, 255, 255);
}
.disappear-container-mobile-view {
  display: none;
}
.collection {
  padding-top: 90px;
  padding-bottom: 64px;
}
.home-tiny-heading {
  text-align: center;
  font-family: Isidora Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
  letter-spacing: 7.68px;
  text-transform: uppercase;
}
.home-heading {
  font-family: Isidora Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 265;
  line-height: 48px; /* 120% */
  text-transform: capitalize;
  /* margin-bottom: 64px; */
}

.home-feedback {
  padding-top: 90px;
  padding-bottom: 64px;
}
.home-feedback .heading1 {
  color: #524a44;
  text-align: center;
  font-family: Isidora Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 7.68px;
  text-transform: uppercase;
}
.home-feedback .heading2 {
  text-align: center;
  font-family: Isidora Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 265;
  line-height: 48px;
  text-transform: capitalize;
}
.feedback-container {
  display: flex;
  gap: 20px;
}
.feedback-comment-container1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.feedback-comment-container2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.comment {
  flex: 1;
  border: 1px solid #110f09;
  padding: 40px;
  border-radius: 24px;
  background: #fdf0d5;
  border: none;
}
.comment.first {
  flex: 0;
}
.two {
  flex: 1;
  border: 1px solid #110f09;
  padding: 40px;
  border-radius: 24px;
  background: #fdf0d5;
  border: none;
}
.right .two p {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 1px;
  text-align: justify;
}
.comment p {
  color: #252525;
  font-family: Isidora Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;
  letter-spacing: -0.32px;
}
.left .one p {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 1px;
  text-align: justify;
}
.image-ratings-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.image-ratings {
  margin-top: 25px;
  margin-left: 10px;
}
.roww {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.roww .image {
  width: 100%;
  height: auto;
}
.home-container {
  padding-top: 90px;
  padding-bottom: 64px;
}
.home-container .tiny-text {
  color: #524a44;
  text-align: center;
  font-family: Isidora Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 7.68px;
  text-transform: uppercase;
}
.home-container .big-text {
  color: #060503;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 265;
  line-height: 48px;
  text-transform: capitalize;
}
.buttonns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.buttonn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 36px;
  background: #000;
  color: #fff;
  border-radius: 120px;
  font-family: Isidora Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  border: none;
  outline: none;
  text-transform: uppercase;
}
.buttonn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 34px;
  background: #fff;
  color: #000;
  border-radius: 120px;
  font-family: Isidora Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  border: 1px solid #000;
  outline: none;
  text-transform: uppercase;
}
.designer-container {
  display: flex;
  padding: 70px 48px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #fdf0d5;
  backdrop-filter: blur(20px);
}
.designer-container img {
  width: 500px;
  height: 500px;
  object-fit: contain;
}
.designer-container-text {
  display: flex;
  padding: 48px 32px;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  flex-direction: column;
}
.designer-container-text h3 {
  color: #252525;
  font-family: Isidora Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
  letter-spacing: 7.68px;
  text-transform: uppercase;
}
.designer-container-text h1 {
  color: #252525;
  font-family: Isidora Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  text-transform: capitalize;
}
.designer-container-text p {
  color: #252525;
  font-family: Isidora Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.discover-btn .designer-btn {
  background: #000;
  color: #fdf0d5;
  padding: 24px 32px;
  font: 20px;
}
.designer-container h3 {
  color: #252525;
  font-family: Isidora Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 7.68px;
  text-transform: uppercase;
}
.designer-container h1 {
  color: #252525;
  font-family: Isidora Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
  text-transform: capitalize;
}
.designer-container p {
  color: #252525;
  font-family: Isidora Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
}
.designer-container img {
  width: 100%;
  object-fit: cover;
  border-radius: 40px;
  object-fit: cover !important;
}
.designer {
  display: flex;
  justify-content: center;
}
.designer .btn2 {
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 38px;
  background: #000;
  color: #fdf0d5;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 120% */
  letter-spacing: 9.6px;
  text-transform: uppercase;
}

.instagram-feed {
  display: flex;
  width: 100%;
  height: 600px;
}
.roww2{
  flex: 1;
  width: 100%;
  height: 100%;
}
.roww2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0;
  /* border: 1px solid #fff; */
  display: block; /* Remove any extra spacing below the image */
  margin: 0; 
}

.insta-feed {
  padding-top: 90px;
  padding-bottom: 64px;
}
.insta-click-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
.insta-click-btn .insta-btn {
  position: absolute;
  left: 20%;
  right: 20%;
  top: -200px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border: 0.2px solid black;
  outline: none;
  background: rgba(255, 255, 255, 0.24);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #060503;
  font-size: 16px;
  text-transform: uppercase;
}
@media (max-width: 480px) {
  .vid-text {
    padding-top: 110px;
    padding-bottom: 20px;
  }
  .vid-text h3 {
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px; /* 160% */
    letter-spacing: 4.8px;
  }
  .vid-text p {
    font-size: 24px;
    font-style: normal;
    font-weight: 265;
    line-height: 32px;
  }

  .disappear-container {
    display: block;
    width: 100%;
  }
  .disappear-container .column2 {
    width: 100%;
  }
  .disappear-container .column2 img {
    width: 100%;
    height: 644px;
    object-fit: cover;
  }
  .discover-btn {
    padding-bottom: 31rem !important;
  }
  .blur-box h3 {
    padding-top: 30px;
  }
  .buttonns {
    gap: 8px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .buttonn1 {
    padding: 12px 20px;
    font-size: 13.8px;
  }
  .buttonn2 {
    padding: 10px 18px;
    font-size: 13.8px;
  }

  .disappear-container {
    display: none;
  }
  .disappearing-text .text {
    font-size: 20px;
    line-height: 10px;
  }
  .discover-btn {
    gap: 2px;
    padding-bottom: 50px;
  }
  .discover-btn .btn2 {
    border-radius: 15px;
    padding: 6px 9px;
  }
  .disappear-container-mobile-view {
    display: block;
  }
  .disappear-container-mobile-view .homeBG1 {
    background: url("https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358243/evi8_xt4el5.jpg");
    width: 100%;
    height: 500px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .disappear-container-mobile-view .homeBG2 {
    background: url("https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358206/access1_maqoim.png");
    width: 100%;
    height: 500px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .homeBG1-content p,
  .homeBG2-content p {
    color: #110f09;
    text-align: center;
    text-shadow: 0px 8px 48px #fff;
    font-family: Isidora Sans;
    font-size: 24px;
    font-style: normal;
    /* font-weight: bold; */
    line-height: 32px;
    text-transform: capitalize;
    margin-bottom: 0px;
  }
  .collection-button {
    display: flex;
    justify-content: center;
  }
  .homeBG1-content button,
  .homeBG2-content button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    border-radius: 38px;
    background: rgba(255, 255, 255, 0.24);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .homeBG1-content button .link,
  .homeBG2-content button .link {
    text-decoration: none;
    color: #060503;
    font-family: Isidora Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 200% */
    letter-spacing: 5.76px;
    text-transform: uppercase;
  }
  .homeBG1-content,
  .homeBG2-content {
    margin-bottom: 20px;
  }
  .collection {
    padding-top: 60px;
    padding-bottom: 20px;
  }

  .collection .home-tiny-heading {
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 4.8px;
  }
  .collection .home-heading {
    font-size: 24px;
    font-style: normal;
    font-weight: 265;
    line-height: 16px;
  }

  .column {
    width: 100% !important;
  }
  .home-container {
    padding-top: 80px;
    padding-bottom: 20px;
  }
  .home-container .tiny-text {
    font-size: 12px;
    letter-spacing: 5.68px;
    text-transform: uppercase;
  }
  .tiny-textt {
    font-size: 10px;
  }
  .big-textt {
    font-size: 24px;
    font-style: normal;
    font-weight: 265;
    line-height: 32px;
  }
  .home-container .big-text {
    font-size: 25px;
    line-height: 40px;
    margin-top: 15px;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    line-height: 32px;
    text-transform: capitalize;
  }

  .roww {
    display: block;
  }
  .row-2 {
    display: none;
  }
  .home-feedback {
    padding-top: 60px;
    padding-bottom: 30px;
  }
  .home-feedback .heading1 {
    font-size: 10px;
    line-height: 16px;
  }
  .home-feedback .heading2 {
    font-size: 24px;
    line-height: 16px;
  }
  .feedback-container {
    flex-direction: row-reverse;
    overflow-x: scroll;
  }
  .comment {
    width: 300px;
    /* height: 150px; */
    padding: 30px;
  }

  .two {
    padding: 30px;
    width: 350px;
    /* height: 100px; */
  }
  .left .one p {
    font-size: 12px;
    line-height: 16px;
  }
  .right .two p {
    font-size: 12px;
    line-height: 16px;
  }
  .image-ratings-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .designer-container h3 {
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px; /* 160% */
    letter-spacing: 4.8px;
    text-align: center;
  }
  .designer-container h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 265;
    line-height: 32px;
    text-align: center;
  }
  .designer-container p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .designer-container img {
    width: 345px !important;
    height: 418.087px !important;
  }
  .designer .btn2 {
    padding: 10px 18px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: 6.72px;
  }
  .instagram-feed {
    height: 170px;
  }
  .roww2 {
    margin-top: 0px;
    text-align: center;
  }
  .insta-post {
    margin-bottom: -5px;
  }
  .insta-feed {
    padding-top: 60px;
    padding-bottom: 20px;
  }

  .insta-click-btn .insta-btn {
    top: -50px;
    padding: 0px 5px;
    font-size: 8px;
    text-transform: uppercase;
  }
}
@media (min-width: 481px) and (max-width: 815px){
  .instagram-feed {
    height: 300px !important;
  }
  
}

@media (min-width: 815px) and (max-width: 1028px){
  .instagram-feed {
    height: 450px;
  }
  
}
